<template>
  <div class="p-5 flex flex-column">
    <div class="tab-container">

      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/settings'"/>
      <div v-if="mobile">
          <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/settings'"/>
          <spacer-h/>
      </div>

      <router-view></router-view>
      <!-- render the personal settings tab if route is /settings -->
      <TabPersonalSettings v-if="isRootPage"/>

      <HelpCard mode="info" class="pt-5" />

    </div>
  </div>
</template>
<script>
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import TabViewHeaders from "@/components/nav/TabViewHeaders";
import TabPersonalSettings from "@/components/tabbed_components/TabPersonalSettings";
import HelpCard from "@/components/common/HelpCard";
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import SpacerH from '@/components/common/layout/SpacerH.vue';



export default {
  name: "Settings",
  components: {
    SpacerH,
    TabPersonalSettings,
    TabViewHeaders,
    HelpCard,
    TabViewDropdown
  },
  setup() {

    const route = useRoute()
    const store = useStore()
    const headers = computed(() => store.getters.getTabHeaders('settings'))
    const mobile = ref(false)
    const screenSize = computed(() => store.getters.getTabView)

    const onTabHeadersChange = (payload) => {
      store.dispatch('setActiveHeader', {page: 'settings', headers: payload})
    }

    const isRootPage = computed(() => {
      return route.path === '/settings'
    })

    watch(screenSize, (val) => {
        mobile.value = val === 'mobile'
    })

    onMounted(() => {
        mobile.value = screenSize.value === 'mobile'
    })


      return {
      headers,
      onTabHeadersChange,
      isRootPage,
      mobile
    }
  },

}
</script>

<style scoped>
button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
  padding: 5px 45px;
}
</style>
